<!-- 用户编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改题目' : '新建题目'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 4 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 20 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="类型:" name="questionType">
            <a-select
              placeholder="请选择题目类型"
              v-model:value="form.questionType"
              @change="form.questionAnswerList = []"
              allow-clear
            >
              <a-select-option
                v-for="item in questionTypeList"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="分数:" name="questionScore">
            <a-input-number
              allow-clear
              placeholder="请输入分数"
              v-model:value="form.questionScore"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item
            label="题目:"
            :labelCol="{ span: 2, offset: 0 }"
            :wrapperCol="{ span: 22, offset: 0 }"
            name="questionName"
          >
            <a-input
              allow-clear
              placeholder="请输入题目"
              v-model:value="form.questionName"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="answer" :gutter="16">
        <a-col
          :md="24"
          :sm="24"
          :xs="24"
          style="padding: 10px; padding-top: 0px"
        >
          <a-button type="primary" :size="small" @click="addClick"
            >添加选项</a-button
          >
        </a-col>
        <a-col
          :md="24"
          :sm="24"
          :xs="24"
          v-for="(item, index) in form.questionChoiceList"
          :key="index"
        >
          <a-form-item
            :wrapperCol="{ span: 24, offset: 0 }"
            name="item.choiceName"
          >
            <a-input
              allow-clear
              placeholder="请输入内容"
              v-model:value="item.choiceName"
            >
              <template #addonBefore>
                <div>{{ letter[index] }}</div>
              </template>
              <template #addonAfter>
                <a @click="deleteClick(index)">删除</a>
              </template>
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item
            label="答案:"
            :labelCol="{ span: 2, offset: 0 }"
            :wrapperCol="{ span: 22, offset: 0 }"
            name="questionAnswer"
          >
            <a-select
              v-model:value="form.questionAnswerList"
              :mode="form.questionType === 0 ? '' : 'multiple'"
              style="width: 100%"
              placeholder="请选择答案"
              @change="handleChange"
              :options="
                form.questionChoiceList.map((choice) => ({
                  value: choice.choiceNumber
                }))
              "
            >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24" style="height: 150px">
          <a-form-item
            label="解析"
            :labelCol="{ span: 2, offset: 0 }"
            :wrapperCol="{ span: 22, offset: 0 }"
            name="questionExplain"
          >
            <a-textarea
              v-model:value="form.questionExplain"
              placeholder="请输入解析"
              :rows="4"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import setting from '@/config/setting'
import * as questionInfoApi from '@/api/qms/questionInfo'
import * as questionChoiceApi from '@/api/qms/questionChoice'
import * as dictDataApi from '@/api/sys/dictData'
export default {
  name: 'navigationEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),

      uploadUrl: setting.uploadUrl,
      // 编辑弹窗表单验证规则
      rules: {
        questionType: [
          {
            required: true,
            message: '请选择题目类型',
            type: 'number',
            trigger: 'blur'
          }
        ],
        questionScore: [
          {
            required: true,
            message: '请输入分数',
            type: 'number',
            trigger: 'blur'
          }
        ],
        questionName: [
          {
            required: true,
            message: '请输入题目',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 题目类型数据
      questionTypeList: [],
      // 定义一个字母数组
      letter: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        if (!this.form.questionChoiceList) {
          this.form.questionChoiceList = []
        }
        if (this.form.questionAnswer) {
          if (this.form.questionType === 1) {
            this.form.questionAnswerList = this.form.questionAnswer.split(',')
          } else {
            this.form.questionAnswerList = this.form.questionAnswer
          }
        } else {
          this.form.questionAnswerList = ''
        }
        this.queryquestionChoice(this.form.questionId)
        this.isUpdate = true
      } else {
        this.form = {
          questionChoiceList: [],
          questionAnswerList: []
        }
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  created() {
    this.queryTypeList()
    this.init()
  },
  methods: {
    init() {
      // 生成26个大写字母集合
      const um = 65
      const uppercaseAlphabet = new Array(26)
        .fill(null)
        .map((v, i) => String.fromCharCode(um + i))
      this.letter = uppercaseAlphabet
    },
    handleChange(e) {
      console.log('选择了', e)
    },
    /* 保存编辑 */
    save() {
      let valid = true
      if (
        this.form.questionAnswerList &&
        this.form.questionType === 1 &&
        this.form.questionAnswerList.length < 2
      ) {
        valid = false
      }
      if (!valid) {
        this.$message.error('至少选择2个答案')
        return
      }

      if (this.form.questionAnswerList) {
        if (this.form.questionType === 1) {
          this.form.questionAnswer = this.form.questionAnswerList.join(',')
        } else {
          this.form.questionAnswer = this.form.questionAnswerList
        }
      } else {
        this.form.questionAnswer = ''
      }
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          questionInfoApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                console.log(res.code)
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {
                    questionChoiceList: [],
                    questionAnswerList: []
                  }
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    // 添加选项按钮
    addClick() {
      this.form.questionChoiceList.push({
        choiceNumber: this.letter[this.form.questionChoiceList.length],
        choiceName: ''
      })
    },
    // 删除选项
    deleteClick(index) {
      // console.log('删除了idid', id)
      // const hide = this.$message.loading('请求中..', 0)
      // questionChoiceApi
      //   .deleteById(id)
      //   .then((res) => {
      //     hide()
      //     if (res.code === 0) {
      //       this.$message.success(res.msg)
      //     } else {
      //       this.$message.error(res.msg)
      //     }
      //   })
      //   .catch((e) => {
      //     hide()
      //     this.$message.error(e.message)
      //   })
      if (this.form.questionChoiceList.length > 2) {
        this.form.questionChoiceList.splice(index, 1)
        this.form.questionAnswerList = []
        this.form.questionChoiceList.forEach((item, i) => {
          item.choiceNumber = this.letter[i]
        })
      } else {
        this.$message.error('选项最少为2条')
      }
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 调选项接口获取questionId
    queryquestionChoice(questionId) {
      questionChoiceApi.page({ questionId }).then((res) => {
        if (res.code === 0) {
          this.form.questionChoiceList = res.data
          this.form.questionChoiceList.map((item) => {
            // console.log('questionChoiceList', item)
          })
        }
      })
    },
    // 获取题目类型字典项数据
    queryTypeList() {
      dictDataApi
        .querydictdata('questionType')
        .then((res) => {
          if (res.code === 0) {
            this.questionTypeList = res.data
            this.questionTypeList.forEach((item) => {
              item.dictDataCode = parseInt(item.dictDataCode)
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style scoped>
.answer {
  padding-left: 8%;
}
</style>
